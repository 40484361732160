import store from '@/store/index'

const hasCredencial = (credencial:string) => {
    const credencials = store.state.permissao.credencial as any;
    const isAdminOrOwner = store.state.permissao.admin || store.state.permissao.owner;
    if (isAdminOrOwner) return true;
    return credencials[credencial] ? true : false;
}

export  {
    hasCredencial
}