import { hasCredencial } from '@/core/utils/credentials.util'

const allowAccess = (to: any, from: any, next: any) => {
    if (!hasCredencial('visualizarAdministracao')) {
        next('/');
        return;
    }

    next();
}

export default allowAccess;
