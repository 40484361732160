import { RouteConfig } from "vue-router";
import allowAccess from './guards/admin.guard';

const adminRoutes: Array<RouteConfig> = [{
  path: 'admin',
  name: 'adminRouter',
  component: () => import('@/views/admin/RouterView.vue'),
  beforeEnter: allowAccess,
  children: [
    {
      name: 'admin',
      path: '',
      component: () => import('@/views/admin/IndexView.vue'),
    },
    {
      name: 'Empresa',
      path: 'empresa/:id',
      component: () => import('@/views/admin/empresa/IndexView.vue'),
    },
    {
      name: 'Categoria',
      path: 'categoria/:id',
      component: () => import('@/views/admin/categoria/IndexView.vue'),
    },
    {
      name: 'Assinatura',
      path: 'assinatura/:id',
      component: () => import('@/views/admin/assinatura/IndexView.vue'),
    },
    {
      name: 'Topico',
      path: 'topico/:id',
      component: () => import('@/views/admin/topico/IndexView.vue'),
    },
    {
      name: 'FormaPgto',
      path: 'forma-pgto/:id',
      component: () => import('@/views/admin/forma-pgto/IndexView.vue'),
    },
    {
      name: 'Cliente',
      path: 'cliente/:id',
      component: () => import('@/views/admin/cliente/IndexView.vue'),
    },
    {
      name: 'Fornecedor',
      path: 'fornecedor/:id',
      component: () => import('@/views/admin/fornecedor/IndexView.vue'),
    },
    {
      name: 'Fabricante',
      path: 'fabricante/:id',
      component: () => import('@/views/admin/fabricante/IndexView.vue'),
    },
    {
      name: 'Produto',
      path: 'produto/:id',
      component: () => import('@/views/admin/produto/IndexView.vue'),
    },
    {
      name: 'PrazoEntrega',
      path: 'prazo-entrega/:id',
      component: () => import('@/views/admin/prazo-entrega/IndexView.vue'),
    },
    {
      name: 'Usuario',
      path: 'usuario/:id',
      component: () => import('@/views/admin/usuario/IndexView.vue'),
    },
  ],
}]


export default adminRoutes;