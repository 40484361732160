import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import authToken from '@/plugins/auth.plugin'
import VCurrencyField from 'v-currency-field';
import { VTextField } from 'vuetify/lib';

Vue.config.productionTip = false

//Interceptors
import '@/core/interceptors/auth.interceptor'

//Auth Plugin
Vue.use(authToken)

//Components
Vue.component('app-color', () => import('@/components/picker-color/PickerColor.vue'))
Vue.component('v-input-currency', () => import("@/components/shared/currency/IndexView.vue"))
Vue.component('v-input-date-picker', () => import("@/components/shared/datepicker/DatePicker.vue"));
Vue.component('v-input-date-picker-field', () => import("@/components/shared/datepicker/DatePickerField.vue"));
Vue.component('app-navbar', () => import("@/components/shared/navbar/Navbar.vue"));
Vue.component('app-notify-field', () => import("@/components/shared/notify-field/NotifyField.vue"));
// Vue.component('app-notification-email', () => import("@/views/orcamento/components/Info.vue"));

//External plugins
import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

//CHARTS
import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)

Vue.component('app-apexchart', VueApexCharts)


//Mask
import VueMask from 'v-mask'
Vue.use(VueMask);

//tiptap-vuetify
import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'

Vue.use(TiptapVuetifyPlugin, {
  vuetify, // same as "vuetify: vuetify"
  iconsGroup: 'mdi'
})

Vue.component('v-text-field', <any>VTextField)

import "@/filters/index";

import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

const options = {
  position: 'bottom-right',
};


Vue.use(Toast, options);

Vue.component('app-paging', () => import("@/components/shared/paging/Paging.vue"))

new Vue({
  router,
  store,
  vuetify,
  render: (h: any) => h(App)
}).$mount('#app')
