import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import store from '@/store/index';
import adminRoutes from './admin/index';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/login',
    component: () => import('@/layouts/LoginLayout.vue'),
    meta: {
      auth: false
    },
    children: [
      {
        path: '',
        name: 'login',
        component: () => import('@/views/login/LoginView.vue')
      },
      // {
      //   path: 'cadastro',
      //   name: 'cadastro',
      //   component: () => import('@/views/plataforma/login/cadastro/IndexView.vue')
      // },
    ],
  },
  {
    path: '/',
    redirect: 'orcamentos',
    meta: {
      auth: true
    },
    component: () => import('@/layouts/AppLayout.vue'),
    children: [{
      path: 'orcamentos',
      name: 'OrcamentoRouter',
      component: () => import('../views/orcamento/RouterView.vue'),
      children: [{
        path: '',
        name: 'OrcamentoView',
        component: () => import('@/views/orcamento/OrcamentoView.vue'),
        children: [{
          path: 'editar/:id',
          name: 'DialogOrcamento',
          component: () => import('@/views/orcamento/dialog/DialogOrcamento.vue'),
          meta: {
            goToBack: '/orcamentos'
          }
        },
        {
          path: 'novo',
          name: 'DialogOrcamento',
          component: () => import('@/views/orcamento/dialog/DialogOrcamento.vue'),
          meta: {
            goToBack: '/orcamentos'
          }
        }]
      }
      ]
    },
    {
      path: 'producao',
      name: 'Producao',
      component: () => import('../views/producao/ProducaoView.vue'),
      children: [{
        path: 'orcamento/:id',
        name: 'Producao',
        component: () => import('@/views/orcamento/dialog/DialogOrcamento.vue'),
        meta: {
          goToBack: '/producao',
          producao: true
        }
      }]
    },
    {
      path: 'meus-negocios',
      name: 'MeusNegocios',
      component: () => import('../views/meus-negocios/MeusNegociosView.vue')
    },
    {
      path: 'calendario',
      name: 'calendario',
      component: () => import('../views/calendario/CalendarioView.vue')
    },
    {
      path: 'relatorio',
      name: 'relatorio',
      component: () => import('../views/relatorio/Router.vue'),
      children: [{
        path: '',
        name: 'IndexView',
        component: () => import('@/views/relatorio/IndexView.vue'),
      }]
    },
    {
      path: 'acesso-negado',
      name: 'AcessoNegado',
      component: () => import('../views/acesso-negado/Index.vue')
    },
    ...adminRoutes
    ]
  },
  {
    path: "*",
    component: () => import('@/views/nao-encontrado/NaoEncontrado.vue'),
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.auth)) {
    if (store.getters.usuarioEstalogado) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router
